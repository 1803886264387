<template>
  <div class="box">
    <div ref='bar' class="bara"></div>
  </div>
</template>

<script>
  export default {
    props: [],
    data() {
      return {
        xdata: ['2016', '2017', '2018', '2019', '2020'],
        data: [12695.8, 13140.7, 13705.6, 13786.5, 13867],
      }
    },
    mounted() {
      // this.first()
      var box = this.$refs.bar
      this.Chart(box, this.data, this.xdata)
    },
    methods: {
      Chart(box, data, xdata) {
        var myChart = this.$echarts.init(box);
        var option;
        option = {
          title: {
            text: '全国印刷业产值分析',
            textStyle: {
              color: "#333333",
              fontSize: 18,
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            right: '6%',
          },
          xAxis: {
            data: xdata,
            axisLabel: {
              textStyle: {
                color: '#333',
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
              }
            },
          },
          dataZoom: [{
            type: 'slider',
            xAxisIndex: 0,
            bottom: 0,
            height: 12,
            showDetail: false,
            startValue: 0,
            endValue: 4,
            handleSize: '110%',
            handleStyle: {
              color: "#d3dee5",
            },
            textStyle: {
              color: "#fff"
            },
            borderColor: "#90979c"
          }, ],
          yAxis: [{
            type: 'value',
            name: "单位:亿元",
            axisLine: {
              show: true,
            },
            axisLabel: {
              formatter: '{value}',
            },
            splitLine: {
              show: false
            },
          }, {
            type: 'value',
            name: '增长率(%)',
            boundaryGap: [0, 0.01],
            axisLabel: {
              formatter: '{value}%',
            },
            splitLine: {
              show: false
            }, //去除网格线
            axisLine: {
              show: true,
            },
          }],
          series: [{
            name: '单位:亿元',
            type: 'bar',
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [{
                    offset: 0,
                    color: '#93fc9d'
                  },
                  {
                    offset: 0.5,
                    color: '#61ebbe'
                  },
                  {
                    offset: 1,
                    color: '#0ecef5'
                  }
                ]
              )
            },
            label: {
              show: true,
              position: "top",
            },
            barWidth: 25,
            data: data
          }, {
            name: '增长率(%)',
            type: 'line',
            yAxisIndex: 1,
            data: ['', 4.30, 3.80, 3.50, 4.30, 0.59, 0.58],
            itemStyle: {
              color: "#84B1F4"
            },
            label: {
              show: true,
              position: "top",
            },
            barGap: "0%"
          }]
        };
        option && myChart.setOption(option);
      },
      // first() {
      //   var year21 = []
      //   var year20 = []
      //   var nowDate = new Date();
      //   let yeardate = {
      //     thisyear: nowDate.getFullYear(),
      //     lastyear: nowDate.getFullYear() - 1
      //   }
      //   this.$axios.get("/apg/industry/overview/analysis?statYear=" + yeardate.thisyear).then(res => {
      //     if (res.data.data) {
      //       res.data.data.forEach(item => {
      //         year21.push(item.productionCount.toFixed(2))
      //       })
      //       // year21=res.data.data
      //     }
      //   })
      //   this.$axios.get("/apg/industry/overview/analysis?statYear=" + yeardate.lastyear).then(res => {
      //     if (res.data.data) {
      //       res.data.data.forEach(item => {
      //         year20.push(item.productionCount.toFixed(2))
      //       })
      //     }
      //   })
      //   var box = this.$refs.bar
      //   setTimeout(() => {
      //     //console.log(year20,year21)
      //     this.Chart(box, year20, year21)
      //   }, 200)
      // },
    }
  }
</script>

<style scoped="scoped" lang="less">
  .box {
    width: 100%;
    height: 100%;

    .bara {
      width: 100%;
      height: 100%;
      background-color: #fff;
      border-radius: 4px;
    }
  }
</style>
