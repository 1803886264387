<template>
  <div class="boxx">
    <div class="bar" ref='bar'></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        data: [
          // {name:'龙腾企业',value:[0,0,0,0,0,4]},
          {
            name: '全国百强企业',
            value: [3, 0, 0, 0, 0, 0]
          },
          {
            name: '高新企业',
            value: [37, 0, 0, 0, 0, 0]
          },
          {
            name: '亿元企业',
            value: [15, 0, 0, 0, 0, 0]
          },
          {
            name: '省科技企业',
            value: [263, 0, 0, 0, 0, 0]
          },
          // {name:'绿色印刷企业',value:[0,0,0,0,0,8]},
        ],
        // colors:[{color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0,color: 'rgba(19,194,194,0.75)'},{offset: 0.25,color: 'rgba(25,168,203,0.67)'},{offset: 0.5,color: 'rgba(33,137,214,0.57)'},{offset: 0.75,color: 'rgba(41,160,226,0.47)'},{offset: 1,color: 'rgba(47,84,235,0.40)'}])}
        // ,{color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0,color: 'rgba(194,19,89,0.75)'},{offset: 0.25,color: 'rgba(203,25,102,0.67)'},{offset: 0.5,color: 'rgba(214,33,142,0.57)'},{offset: 0.75,color: 'rgba(226,41,207,0.47)'},{offset: 1,color: 'rgba(194,47,235,0.4)'}])},
        // {color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0,color: 'rgba(194,156,19,0.75)'},{offset: 0.25,color: 'rgba(197,203,25,0.67)'},{offset: 0.5,color: 'rgba(160,214,33,0.57)'},{offset: 0.75,color: 'rgba(177,226,41,0.47)'},{offset: 1,color: 'rgba(163,235,47,0.4)'}])},
        // {color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0,color: 'rgba(194,19,19,0.75)'},{offset: 0.25,color: 'rgba(203,46,25,0.67)'},{offset: 0.5,color: 'rgba(214,84,33,0.57)'},{offset: 0.75,color: 'rgba(226,93,41,0.47)'},{offset: 1,color: 'rgba(235,107,47,0.4)'}])},
        // {color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0,color: 'rgba(19,42,194,0.75)'},{offset: 0.25,color: 'rgba(25,55,203,0.67)'},{offset: 0.5,color: 'rgba(33,51,214,0.57)'},{offset: 0.75,color: 'rgba(41,50,226,0.47)'},{offset: 1,color: 'rgba(97,47,235,0.4)'}])},
        // {color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0,color: 'rgba(101,19,194,0.75)'},{offset: 0.25,color: 'rgba(129,25,203,0.67)'},{offset: 0.5,color: 'rgba(175,33,214,0.57)'},{offset: 0.75,color: 'rgba(177,41,226,0.47)'},{offset: 1,color: 'rgba(169,47,235,0.4)'}]),}],
        colors: [{
          color: "#00e4eb"
        }, {
          color: "#fdc56a"
        }, {
          color: "#85a06a"
        }, {
          color: "#4272bC"
        }, {
          color: "#b8b0fb"
        }, {
          color: "#46e490"
        }],
      }
    },
    mounted() {
      var box = this.$refs.bar
      var arr = []
      this.data.forEach((item, i) => {
        arr.push({
          name: item.name,
          type: 'bar',
          data: item.value,
          barWidth: 16,
          label: {
            show: true,
            position: "top",
          },
          itemStyle: {
            normal: this.colors[i]
          }
        })
      })
      this.chart(box, this.data, arr)
    },
    methods: {
      chart(box, data, arr) {
        var myChart = this.$echarts.init(box);
        var option;
        option = {
          title: {
            text: '龙港企业类型分析',
            textStyle: {
              color: "#333333",
              fontSize: 18,
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#666666'
              }
            }
          },
          grid: {
            // left:"15%",
            left: '5%',
            right: '4%',
            bottom: '15%',
            top: '27%'
          },
          legend: {
            data: ['全国百强企业', '高新企业',  '亿元企业','省科技企业'],
            // width:"17%",
            right:"1%",
            selected: {
              '龙腾企业': true,
              '百强企业': true,
              '亿元企业': true,
              '省科技企业': true,
              '绿色印刷企业': true
            },
          },
          dataZoom: [{
            type: 'slider',
            xAxisIndex: 0,
            bottom: 0,
            height: 12,
            showDetail: false,
            startValue: 0,
            endValue: 1,
            handleSize: '110%',
            handleStyle: {
              color: "#d3dee5",
            },
            textStyle: {
              color: "#fff"
            },
            borderColor: "#90979c"
          }, ],
          xAxis: [{
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            data: ['2020年', '2021年', '2022年', '2023年', '2024年', '2025年'],
            axisLine: {
              show: true,
            },
          }],
          yAxis: [{
            min: 0,
            position: 'left',
            axisLine: {
              show: true,
            },
            splitLine: {
              show: false
            },
            name: "单位:家",
          }],
          series: arr,
        };

        option && myChart.setOption(option);
      }
    },
  }
</script>

<style lang="less" scoped="scoped">
  .boxx {
    width: 100%;
    height: 100%;

    .bar {
      width: 100%;
      height: 100%;
      background-color: #FFFFFF;
      border-radius: 4px;
    }
  }
</style>
