<template>
	<!-- 所有的最外层div都需要设置 id='box' -->
	<div id="box">
		<div class="box">
			<div class="top">
				<el-card shadow="hover">
				    <p class="hovetop">总产值</p>
					<p class="hovebom"><span>{{productionvalue}}</span><span>亿元</span></p>
				</el-card>
				<el-card shadow="hover">
				    <p class="hovetop">总税收</p>
					<p class="hovebom"><span>{{revenue}}</span><span>亿元</span></p>
				</el-card>
				<el-card shadow="hover">
				    <p class="hovetop">企业</p>
					<p class="hovebom"><span>{{firmnum}}</span><span>家</span></p>
				</el-card>
				<el-card shadow="hover">
				    <p class="hovetop">员工</p>
					<p class="hovebom"><span>{{staffnum}}</span><span>人</span></p>
				</el-card>
				<el-card shadow="hover">
				    <p class="hovetop">用地</p>
					<p class="hovebom"><span>{{floorspace}}</span><span>亩</span></p>
				</el-card>
				<el-card shadow="hover">
				    <p class="hovetop">园区</p>
					<p class="hovebom"><span>{{garden}}</span><span>个</span></p>
				</el-card>
			</div>
			<div class="bom">
				<div class="leftone">
					<bargraph></bargraph>
				</div>
				<div class="rightone">
					<piegraph></piegraph>
				</div>
				<div class="lefttwo">
					<progressbar></progressbar>
				</div>
				<div class="righttwo">
					<funnel></funnel>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import bargraph from "@/components/bargraph"
	import piegraph from "@/components/piegraph"
	import progressbar from "@/components/industrialprofile/progressbar"
	import funnel from "@/components/industrialprofile/funnel"
	import {information} from "@/api/industrialprofile/industrialprofile"
	export default {
		data() {
			return {
				productionvalue: 162.5,//产值
				revenue:2.99,//税收
				firmnum:3906,//企业数
				staffnum:22231,//员工
				floorspace:3190.32,//占地面积
				garden:20,//园区
			}
		},
		components:{
			bargraph,
			piegraph,
			progressbar,
			funnel
		},
    mounted() {
		  this.getInformation()
    },
    methods:{
      getInformation(){
        let _this = this
		information().then(res=> {
          console.log(res.data.data)
          if(res.data.data){
            let industry = res.data.data
            // _this.productionvalue = industry.productionCount==null?0:parseFloat(industry.productionCount/10000).toFixed(2)
            // _this.revenue = industry.taxCount==null?0:parseFloat(industry.taxCount/10000).toFixed(2)
            // _this.firmnum = industry.tenantCount==null?0:industry.tenantCount
            // _this.staffnum = industry.empCount==null?6094:industry.empCount
            // _this.floorspace = industry.areaCount==null?0:industry.areaCount
            // _this.garden = industry.projectCount==null?0:industry.projectCount
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped="scoped">
	.box{
		width: 100%;
		background-color: #f2f3f5;
		border-radius: 4px;
	}
	.top{
		display: flex;
		.hovetop{
			font-size: 18px;
			margin-bottom: 16px;
		}
		.hovebom{
		}
		.hovebom>span:nth-child(1){
			font-size: 24px;
			margin-right: 16px;
			font-weight: 700;
		}
	}
	.top>.el-card{
		width: 197px;
		margin-left:8px ;
		height: 105px;
	}
	.top>.el-card:nth-child(1){
		margin-left: 0;
	}
	.bom{
		width: 100%;
		min-height:500px ;
		border-radius: 4px;
		margin-top: 20px;
		display: flex;
		flex-wrap: wrap;
		.leftone{
			width: 49%;
			height: 300px;
			border-radius: 4px;
		}
		.rightone{
			width: 49%;
			height: 300px;
			border-radius: 4px;
			margin-left: auto;
		}
		.lefttwo{
			width: 49%;
			height: 300px;
			border-radius: 4px;
			margin-top: 10px;
		}
		.righttwo{
			width: 49%;
			height: 300px;
			border-radius: 4px;
			margin-left: auto;
			margin-top: 10px;
		}
	}
</style>
