<template>
  <!-- <div class="box">
		<div class="title">
      <h1>企业分布图（家）</h1>
      <div class="titleitem">
          <p>规上</p>
          <p style="background-color: #EBA116;"></p>
          <p>规下</p>
          <p style="background-color: #AFD7DE;"></p>
      </div>
    </div>
		<div class="progressbarbox">
			<div class="progressbar" v-for="(item,i) in distributionData" :key="i">
				<p class="icon">
					<img v-if="i<=2" :src="i==0?require('@/assets/img/排名一.png'):i==1?require('@/assets/img/排名二.png'):require('@/assets/img/排名三.png')" />
					<span v-if="i>2">{{i+1}}</span>
				</p>
				<p class="placename">{{item.name}}</p>
				<p class="count">{{item.up}}</p>
				<div class="bar">
					<div class="bars" :style="{width:item.ratio}"></div>
				</div>
				<p class="count">{{item.down}}</p>
				<p class="count">{{item.ratio}}</p>
			</div>
		</div>
	</div> -->
  <div class="boxx">
    <div class="bar" ref="line"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        // distributionData: [],
        // all:
        //   {up:0,//总规上多少家
        //     down:0//总规下多少家
        //  }
        chanlist: [68.50, 89.64, 123.57, 149.51, 162.5], //全部产值
        shuilist: [0, 0, 0, 0, 2.99], //全部税收
        gaugechanlist: [29.61, 38.65, 53.28, 63.25, 66.21], //规上产值
        gaugeshuilist: [0, 0, 0, 0, 2.28], //规上税收

      }
    },
    mounted() {
      // this.getDistribution();
      var box = this.$refs.line
      this.chart(box)
    },
    methods: {
      chart(box) {
        var myChart = this.$echarts.init(box);
        var option;
        option = {
          title: {
            text: '龙港印刷业产税分析',
            textStyle: {
              color: "#333333",
              fontSize: 18,
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            },
            textStyle: {
              fontSize: this.$fontSize
            }
          },
          legend: {
            data: ['全部产值', '全部税收', '规上产值', '规上税收'],
            icon: "roundRect",
            width:"80%",
            right:"0"
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top:"20%",
            containLabel: true
          },
          xAxis: [{
            type: 'category',
            boundaryGap: false,
            data: ['2016', '2017', '2018', '2019', '2020'],
          }],
          yAxis: [{
            type: 'value',
            splitLine: {
              show: false
            },
            name: "单位:亿元",
          }],
          series: [{
              name: '全部产值',
              type: 'line',
              label: {
                show: true,
                position: "top",
              },
              emphasis: {
                focus: 'series'
              },
              data: this.chanlist,
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 1, 0,
                  [{
                      offset: 0,
                      color: '#f0f14b'
                    },
                    {
                      offset: 0.5,
                      color: '#f3dc3f'
                    },
                    {
                      offset: 1,
                      color: '#fe870d'
                    }
                  ]
                )
              },
            },
            {
              name: '全部税收',
              type: 'line',
              label: {
                show: true,
                position: "top",
              },
              emphasis: {
                focus: 'series'
              },
              data: this.shuilist,
              itemStyle: {
                color: '#ff00ff'
              },
            }, {
              name: '规上产值',
              type: 'line',
              label: {
                show: true,
                position: "top",
              },
              emphasis: {
                focus: 'series'
              },
              data: this.gaugechanlist,
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 1, 0,
                  [{
                      offset: 0,
                      color: '#01bee6'
                    },
                    {
                      offset: 0.5,
                      color: '#23c5e2'
                    },
                    {
                      offset: 1,
                      color: '#cee7ca'
                    }
                  ]
                )
              },
            }, {
              name: '规上税收',
              type: 'line',
              label: {
                show: true,
                position: "top",
              },
              emphasis: {
                focus: 'series'
              },
              data: this.gaugeshuilist,
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 1, 0,
                  [{
                      offset: 0,
                      color: '#d7eb59'
                    },
                    {
                      offset: 0.5,
                      color: '#a0f47a'
                    },
                    {
                      offset: 1,
                      color: '#6afc99'
                    }
                  ]
                )
              },
            }
          ]
        };
        var chanval = Math.max.apply(null, this.chanlist)
        var shuival = Math.max.apply(null, this.shuilist)
        if (chanval > shuival) {
          option.series[1].z = 10
        } else if (chanval < shuival) {
          option.series[0].z = 10
        }

        option && myChart.setOption(option);
      }


      // getDistribution(){
      //   let _this = this
      //   _this.$axios({
      //     url:`/apg/industry/overview/distribution`,
      //     method:'get',
      //     data: {}, // body参数
      //     params:{} // query参数
      //   }).then(res=> {
      //     //console.log(res)
      //     //console.log(res.data.data.all)
      //     if(res.data.data.all){
      //       _this.all.up = res.data.data.all.up
      //       _this.all.down = res.data.data.all.down
      //     }
      //     if(res.data.data.list){
      //       _this.distributionData = res.data.data.list
      //     }
      //   })
      // },
    }
  }
</script>

<style lang="less" scoped>
  .boxx{
  	width: 100%;
  	height: 100%;
  	.bar{
  		width: 100%;
  		height: 100%;
      background-color: #fff;
      border-radius: 4px;
  	}
  }
  // .box{
  // 	width: 100%;
  // 	height: 100%;
  // 	background-color: #ffffff !important;
  // 	padding: 4px;
  //    .title{
  //      display: flex;
  //      align-items: center;
  //      justify-content: space-between;
  //      width: 100%;
  //      height: 21px;
  //      h1{
  //      	font-size: 18px;
  //      	margin: 0;
  //      }
  //      .titleitem{
  //        width: 200px;
  //        display: flex;
  //        align-items: center;
  //        p{
  //          width: 40px;
  //          height: 21px;
  //          margin: 5px;
  //        }
  //      }
  //    }

  // 	.icon{
  // 		width: 80px;
  // 		height: 100%;
  // 		display: flex;
  // 		justify-content: center;
  // 		align-items: center;
  // 		img{
  // 			height: 76%;
  // 		}
  // 		span{
  // 			font-size: 18px;
  // 			font-weight: bold;
  // 		}
  // 	}
  // 	.progressbarbox{
  // 		width:90%;
  // 		margin-left: 4%;
  // 		margin-top: 5%;
  // 	}
  // 	.progressbar{
  // 		display: flex;
  // 		width: 100%;
  // 		height: 40px;
  // 		align-items: center;
  // 		justify-content: space-between;
  // 		.placename{
  // 			width: 17%;
  // 		}
  // 		.count{
  // 			width: 8%;
  // 			text-align: center;
  // 		}
  // 		.bar{
  // 			width: 50%;
  // 			background-color: #AFD7DE;
  // 			border-radius: 0 4px 4px 0;
  // 			height: 17px;
  // 			.bars{
  // 				width: 0%;
  // 				background-color: #EBA116;
  // 				height: 100%;
  // 				border-radius: 0 4px 4px 0;
  // 			}
  // 		}
  // 	}
  // }
</style>
