<template>
	<div class="box">
		<div ref='pie' class="bara"></div>
	</div>
</template>

<script>
	import {printingType} from "@/api/piegraph/piegraph"
	export default {
		data() {
			return {
			  printTypeData:[
			    {name:"平版胶印",value:25.90},
			    {name:"凹版印刷",value:13.86},
			    {name:"凸（柔）版印刷",value:2.63},
			    {name:"丝网印刷",value:0.68},
			    {name:'其他',value:23.14}
			  ]
			}
		},
		mounted() {
      this.getPrintingType();
		},
		methods:{
      getPrintingType(){
        let _this = this
		printingType().then(res=> {
         console.log(res)
          if(res.data.data){
            let industry = res.data.data
      /*      industry.forEach(item=>{
              if(item.printingTypeName){
                let length =item.printingTypeName.toString().length
                let printingTypeName =  length > 6 ?
                  (item.printingTypeName.slice(0,6)+"\n"+item.printingTypeName.slice(6,length)) : item.printingTypeName
                _this.printTypeData.push({
                  "value":item.printingTypeNum,
                  "name":printingTypeName
                })
              }
            })*/
            var box =this.$refs.pie
            this.Chart(box)
          }
        })
      },
			Chart(box){
				var myChart =this.$echarts.init(box);
				var option;
				option = {
					title: {
					    text: '规上企业印刷类型产值分析',
						textStyle:{
							color:"#333333",
							fontSize: 18,
						}
					},
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b} : {c} 亿元({d}%)'
					},
					// color: ["#1165B2","#70980C","#398912","#B17706","#502092"],
          color: ["#5470c6","#91cc75","#fac858","#ee6666","#73c0de",'#3ba272','#fc8452'],
				    legend: {
						width:"17%",
						right:"1%"
				    },
				    series: [
				        {
				            name: '印刷种类',
				            type: 'pie',
				            radius: [20, 100],
				            center: ['45%', '50%'],
				            roseType: 'area',
				            itemStyle: {
				                borderRadius: 8,
				            },
				            data: this.printTypeData,
							label:{
								show:true,
								formatter: '{b} :\n {c} 家({d}%)'
							}
				        }
				    ]
				};
				option && myChart.setOption(option);
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.box{
		width: 100%;
		height: 100%;
		.bara{
			width: 100%;
			height: 100%;
			background-color: #fff;
			border-radius: 4px;
		}
	}
</style>
